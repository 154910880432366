declare global {
  interface ENV {
    NODE_ENV: typeof process.env.NODE_ENV;
    SHOPIFY_ENV: typeof process.env.SHOPIFY_ENV;
    OTLP_URL: typeof process.env.OTLP_URL;
    SHOPIFY_BUILD_BRANCH: typeof process.env.SHOPIFY_BUILD_BRANCH;
    SPIN: typeof process.env.SPIN;
    SHOPIFY_API_KEY: typeof process.env.SHOPIFY_API_KEY;
    SHOPIFY_BUILD_COMMIT: typeof process.env.SHOPIFY_BUILD_COMMIT;
    SHOPIFY_APP_URL: typeof process.env.SHOPIFY_APP_URL;
    STATSD_ADDR: typeof process.env.STATSD_ADDR;
    STATSD_DEFAULT_TAGS: typeof process.env.STATSD_DEFAULT_TAGS;
    SHOPIFY_DEVELOPMENT_APP_ID: typeof process.env.SHOPIFY_DEVELOPMENT_APP_ID;
  }
  interface Window {
    ENV: ENV;
  }
}

export const env = <TReturnType extends string | undefined = string>(
  key: keyof ENV,
  defaultValue?: TReturnType,
) => {
  if (
    typeof window !== 'undefined' &&
    Object.prototype.hasOwnProperty.call(window?.ENV ?? {}, key)
  ) {
    return window.ENV[key as keyof typeof window.ENV] as TReturnType;
  }

  if (
    typeof process !== 'undefined' &&
    Object.prototype.hasOwnProperty.call(process.env, key)
  ) {
    return process.env[key] as TReturnType;
  }

  return defaultValue;
};

export const isDevelopment = env('NODE_ENV') === 'development';
export const isProduction = env('NODE_ENV') === 'production';
export const isStaging = /staging/.test(env('SHOPIFY_BUILD_BRANCH') ?? '');
export const isSpin = env('SPIN') === '1';
